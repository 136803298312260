.header {
  position: fixed;
  width: 100%;
  background-color: var(--color-header-bg);

  box-shadow: 0px 2px 10px 0px rgba(99, 66, 124, 0.7);

  display: flex;
  align-items: center;

  height: var(--header-height);
}

.header a {
  width: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.logo {
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
