

ul {
  margin: 0;
  list-style: none;
  display: flex;
}

li {
  display: inline-block;
  /* border-bottom: solid 2px transparent; */
  /* background-color: var(--color-nav-bg); */
  border-right: 2px solid var(--color-header-bg);
  transition: all 0.2s, color 0.2s;
}





li:hover {
  /* background-color: var(--color-nav-bg-active); */
}

li a {
  text-decoration: none;
  font-size: 1.25rem;
  font-weight: 700;
  text-transform: uppercase;
  transition: all 0.2s, color 0.2s;

  width: 150px;
  height: 86px;

  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--color-nav-text);
}

li a:hover {
  color: var(--color-nav-text-active);
}

@media screen and (max-width: 991px) {
  ul {
    padding: 0;
  }
  
  .hide {
    display: none;
  }

  .nav {
    width: calc(100% - 140px);
    padding-left: 20px;
  }
}

