.footer {
  background-color: var(--color-footer-bg);
  color: var(--color-footer-text);
  padding: 100px 0 20px 0;
  font-size: 13px;
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: 0 50px;
}

.footerleft{
  width: 75%;
}

.footerbottom {
  border-top: 1px solid var(--color-footer-text);
  width: 100%;
  text-align: center;
  padding-top: 20px;
}

.footer a {
  color: var(--color-footer-link);
  text-decoration: none;
}

.footerbottom a {
  color: var(--color-footer-text-highlight);
  text-decoration: none;
}

.footerbottom br {
  display: none;
}

@media screen and (max-width: 991px) {
  .hide {
    display: none;
  }

  .footer {
    padding: 40px 0 20px 0;
  }
  
  .footerleft {
    margin-bottom: 40px;
  }

  .footerbottom br {
    display: block;
  }

  .footerright {
    display: none;
  }
}
