a.anchor {
  display: block;
  position: relative;
  top: calc(var(--header-height) * -1);
  visibility: hidden;
}

.services {
  background-color: var(--color-bright-section-bg);
  padding: 10px 50px;

  text-align: center;
}

/* .container {
  display: flex;
  border: solid 4px #63427C;
  padding: 24px;
} */

.container {
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;

  display: flex;
  flex-wrap: wrap;
}

.services h1 {
  color: var(--color-bright-section-header);
  font-size: 3rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;

  /* text-transform: uppercase; */

  padding-bottom: 60px;
}

.iconblock {
  /* width: 33%; */
  flex-basis: 30%;
  text-align: center;
  padding: 0 10px;
}

.iconblock h3 {
  font-size: 1.5rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--color-bright-section-header);
}

.iconblock p {
  color: var(--color-bright-section-text);
  font-size: 15px;
}

.iconblock img {
  height: 100px;

  max-height: 100%;
  max-width: 100%;
}


@media (max-width: 768px) {
  .iconblock {
    flex-basis: 50%;
  }

  .container {
    flex-direction: column;
  }
}