.about {
  color: var(--color-dark-section-text);
  background-color: var(--color-dark-section-bg);

  padding: 10px 70px;
}

a.anchor {
  display: block;
  position: relative;
  top: calc(var(--header-height) * -1);
  visibility: hidden;
}
