.float {
  position: fixed;
  /* height: 60px; */
  bottom: 40px;
  right: 40px;
  background-color: var(--color-tb-m);
  color: #FFF;
  border-radius: 50px;
  text-align: center;
  text-decoration: none;
  animation: bounce .5s infinite alternate;
}

.hide {
  display: none;
}

.myfloat {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 1.5rem;
}

@media screen and (min-width: 991px) {
  .float {
    display: none;
  }
}

@-webkit-keyframes bounce {
  to {
    -webkit-transform: scale(1.1);
  }
}

@-moz-keyframes bounce {
  to {
    -moz-transform: scale(1.1);
  }
}

@keyframes bounce {
  to {
    transform: scale(1.1);
  }
}