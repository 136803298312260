.contact {
  color: var(--color-bright-section-text);
  background-color: var(--color-bright-section-bg);

  padding: 25px 70px;
}

.contact h2 {
  color: var(--color-bright-section-header);
  /* font-size: 4rem;
  font-weight: 400;
  letter-spacing: 1px;
  text-align: center;

  padding-bottom: 100px; */
}

.contactinfo {
  text-align: center;
}

.icon {
  width: 32px;
  height: 32px;
  margin-top: 10px;
}

a.anchor {
  display: block;
  position: relative;
  top: calc(var(--header-height) * -1);
  visibility: hidden;
}
