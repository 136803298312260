.highlight {
  /* color: var(--color-floating-text-highlight); */

  font-style: italic;
  text-decoration: underline;
  text-decoration-color: var(--color-floating-text-highlight);

  text-shadow: none;
}

.floating {
  font-size: 3rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: var(--color-floating-text);

  text-shadow: 1px 1px #000;
}

a.anchor {
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}
