html {
  /* logo colours -- DO NOT DELETE THESE! */
  --color-background: #0F0C1B;
  --color-border: #63427C;
  --color-logo-text: #FFFFFF;
  
  /* """monster energy""" green */
  --color-green: #96AE57;

  /* the AI came up with some of these */
  --color-red: #D72473;
  --color-blue: #42787C;
  --color-yellow: #B9A65C;
  --color-orange: #B97C5C;


  --color-light-purple: #DBAEFF;

  --color-tb-m: #D72473;
  --color-tb-t: #47D1AB;

  /* use these to make changes to the site */
  --color-secondary: var(--color-green);

  --color-footer-bg: #211C22;
  --color-footer-text: #BBBEC0;
  --color-footer-text-hover: #FFFFFF;
  --color-footer-text-highlight: var(--color-tb-m);
  --color-footer-link: #FFFFFF;


  --color-header-bg: #000;

  --color-nav-text: #FFF;
  --color-nav-bg: #303030;
  --color-nav-text-active: var(--color-border);
  --color-nav-bg-active: #FFF;

  --color-bright-section-bg: #FFF;
  --color-bright-section-header: var(--color-border);
  --color-bright-section-text: #000;
  /* --color-bright-section-link: var(--color-border); */

  --color-dark-section-bg: var(--color-background);
  --color-dark-section-text: #FFF;
  --color-dark-section-link: var(--color-border);


  
  --color-floating-text: var(--color-dark-section-text);
  --color-floating-text-highlight: var(--color-tb-m);  
  

  --header-height: 120px;

  /* --color-primary: var(--color-blue); */
}


@import url('https://fonts.googleapis.com/css?family=Montserrat');

html,
body {
  height: 100%;
  scroll-behavior: smooth;
}

.wrapper {
  height: 100vh;
  font-family: 'Montserrat', sans-serif;
  line-height: 1.5;
  word-spacing: 4px;
  letter-spacing: 1px;
}

.fixed-bg {
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 100%;
  width: 100%;
  color: white;
  text-align: center;
  display: table;
}

.fixed-bg h1 {
  display: table-cell;
  vertical-align: middle;
}

.scroll-bg {
  background-color: var(--color-background);
  padding: 10px 70px;
  color: #676767;
}

.bg-1 {
  background-image: url('mexico.jpg');
}

.bg-2 {
  background-image: url('toronto.jpg');
}

.bg-3 {
  background-image: url('atlanta.jpg');
}

@media screen and (max-width: 992px) {
  .bg-1,
  .bg-2,
  .bg-3 {
    background-attachment: scroll;
  }
}
